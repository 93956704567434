:root {
  --color-white: #ffffff;
  --color-subheader: #697077;
  --color-background: #21272A;
  --gradient-button: linear-gradient(to right, #8A3FFC, #D02670);
  --gradient-text: linear-gradient(to right, #8A3FFC, #D02670);
  --green-gradient: linear-gradient(to right, #D6FC3F, #29D026);
}

.gradientBorder {
  border: 2px solid transparent;
  background-image: linear-gradient(var(--color-background), var(--color-background)), radial-gradient(circle at top left, #8A3FFC, #D02670);  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-radius: 8px;
}

.buttonBase {
  cursor: pointer;
  color: var(--color-white);
  padding: 20px 40px;
  margin-top: 20px;
  text-align: center;
  font-weight: bolder;
  font-size: 1.5em;
  transition: color 0.3s;
  border: 2px solid transparent;
  background-image: linear-gradient(var(--color-background), var(--color-background)), radial-gradient(circle at top left, #8A3FFC, #D02670);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-radius: 8px;
}

.greenGradientText {
  background-image: var(--green-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.container {
  color: var(--color-white);
  text-align: center;
  margin-top: 40px;
}



/* Mobile view */
@media (max-width: 508px) {
  .subheader {
    padding-left: 5%;
    padding-right: 5%;
  }
  .buttonBase {
    padding: 20px 25px;
  }
  .header {
    padding-left: 5%;
    padding-right: 5%;
  }
  .greenGradientText {
    padding-left: 16%;
    padding-right: 16%;
  }

}

.header {
  color: var(--color-white);
  margin-top: 100px;
}

.subheader {
  color: var(--color-subheader);
}


.filledButton {
  background-image: linear-gradient(to right, #8A3FFC, #D02670) !important;
  color: var(--color-white);
  padding: 15px 30px;
  font-size: large;
}

.filledButton:hover {
  padding: 16px 31px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.box {
  max-width: 300px;
  max-height: 300px;
  min-width: 300px;
  min-height: 300px;
  object-fit: scale-down;
  width: 50vw;
  height: 50vw;
}

.loadingImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  justify-items: center;
  margin: auto;
}

@media (max-width: 768px) {
  .imageGrid {
    grid-template-columns: 1fr;
  }
}

.imageGrid img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
}

a {
  color: var(--color-subheader);
}