.container {
  margin-top: 60px;
}

.guidelines {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px 30px;
  list-style: none;
  padding: 0;
  width: fit-content;
  margin: 30px auto;
  margin-top: 0px;
}

.guidelineItem {
  display: flex;
  font-size: 1em;
  width: fit-content;
  color: var(--color-subheader)
}

.PhotoUpload {
  display: flex;
  justify-content: center;
}

.photoUploadContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 80px;
}


/* Responsive design for the guidelines */
@media (max-width: 768px) {
  .guidelines {
    grid-template-columns: 1fr;
    /* Change to a single column layout */
    gap: 10px;
  }
    .guidelineItem:nth-child(3) {
      order: 2;
    }
    .guidelineItem:nth-child(5) {
      order: 3;
    }
    .guidelineItem:nth-child(2) {
       order: 4;
     }
    .guidelineItem:nth-child(4) {
      order: 5;
    }
    .guidelineItem:nth-child(6) {
      order: 6;
    }
}

/* Responsive design for the photo upload container */
@media (max-width: 950px) {
  .photoUploadContainer {
    flex-direction: column;
    /* Stack the child elements vertically */
  }
}