
.imageWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95vw;
}

.iconButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffffff55;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.iconButton:hover {
  background-color: #ffffff;
}

.downloaded {
  background-color: transparent;
  scale: 1.5;
}

.downloaded:hover {
  background-color: transparent;
}

.loading {
  cursor: default;
  margin-top: 3px;
}

.discountButton {
  padding: 10px 25px !important;
  font-size: medium !important;
}

.textButton {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-subheader);
}

.downloadButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: fit-content;
  flex-direction: column;
}

.horizontalButtonContainer {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  flex-direction: row;
  column-gap: 20px;
}

.actionButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 40px;
  }

.downloadText {
  margin-top: -25px;
  margin-left: 17px;
}

.downloadButton {
  padding: 10px 25px !important;
  border-color: var(--color-subheader);
  font-size: medium !important;
  width: fit-content;
}

.linkButton {
  padding: 10px 25px !important;
  font-size: medium !important;
  border-color: var(--color-subheader);
}

.linkButton:hover, .downloadButton:hover {
  color: white;
  border: 2px solid transparent;
  border-image-slice: 1;
  background-image: linear-gradient(#21272A, #21272A), radial-gradient(circle at top left, #8A3FFC, #D02670);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: color .5s;
}


.linkButton:hover .buttonText, .discountButton:hover .buttonText, .downloadButton:hover .buttonText {
  color: transparent;
  background-image: radial-gradient(circle at top left, #8A3FFC, #D02670);
  -webkit-background-clip: text;
  background-clip: text;
}


.nowrap {
  white-space: nowrap !important;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-between;
  height: 45vh;
  margin-bottom: 45vh;
}

.line {
  border-top: 1px solid #2a2f31;
  width: 90vw;
  max-width: 570px;
  margin: auto;
  margin-top: 100px;
}

@media screen and (max-width: 508px) {
  .downloadText {
    margin-left: 0px;
  }
  .horizontalButtonContainer {
    column-gap: 10px;
  }
  .downloadButton {
    padding: 10px 15px !important;
  }
}