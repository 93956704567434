.header {
  color: var(--color-white);
  margin-bottom: 60px;
  font-size: 3em;
  margin-top: 60px;
}

.hero {
  width: 90vw;
  max-width: 900px;
  height: auto;
  object-fit: cover;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
}

.createButton {
  font-size: 1.5em !important;
  color: white;
  width: 325px;
  padding: 20px !important;
  background-image: linear-gradient(to right, #8A3FFC, #D02670) !important;
  cursor: pointer;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
}

.createButton:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
}

.createButtonSticky {
  position: fixed;
  top: 29px;
  right: 20px;
  z-index: 100;
  width: 200px;
  font-size: 1em !important;  
  padding: 15px !important;
}

.tutorial {
  width: 90vw;
  max-width: 1010px;
  margin-top: 40px;
  margin-bottom: 50px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  margin-bottom: 40px;
}

.celebrityContainer {
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 200px;
}

.celebrityGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 80px;
  grid-gap: 80px;
;}

@media (max-width: 900px) {
  .celebrityGrid {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
  .celebrityContainer {
    padding: 0 10px;
  }
  .createButtonSticky {
    width: 220px;
  }

}

@media (max-width: 410px) {
  .shareCard {
    width: 100% !important;
  }
}

.shareCard {
  width: 400px;
  max-width: 700px;
  height: auto;
  object-fit: cover;
  box-shadow: 0px 1.668px 83.377px 2.084px rgba(0, 0, 0, 0.40);
}


.twitterSection {
  margin-top: 100px;
}