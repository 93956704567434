.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  margin-bottom: 0px;
  margin-top: 40px;
}

.optionButton {
  padding: 15px 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: var(--color-background);
  color: white;
  border-radius: 8px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border: 2px solid transparent;
  font-size: 1em;
  font-weight: bold;
}

.leftOption {
  background-image: linear-gradient(var(--color-background), var(--color-background)), radial-gradient(circle at top left, #8A3FFC, #D02670);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
}

.rightOption {
  background-image: linear-gradient(var(--color-background), var(--color-background)), radial-gradient(circle at top left, #D02670, #8A3FFC);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
}

.leftOptionButtonActive {
  background-image: linear-gradient(to right, #8A3FFC, #D02670);
  padding: 17px 50px;
}
.rightOptionButtonActive {
  background-image: linear-gradient(to left, #8A3FFC, #D02670);
  padding: 17px 50px;
}

.descriptionContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
} 

.descriptionContainer img {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
    .descriptionContainer {
      margin-right: 50px;
      margin-left: 50px;
    }
}