.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border: 2px dashed var(--color-subheader);
  border-radius: 12px;
  color: var(--color-subheader);
}

.upload {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.buttonText {
  font-size: 1.25em;
  text-align: center;
  padding: 20px;
  font-weight: 500;
}

.input {
  display: none;
}

.preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.preview:hover {
  cursor: pointer;
}

.container:hover .buttonText {
  color: transparent;
  background-image: radial-gradient(circle at top left, #8A3FFC, #D02670);
  -webkit-background-clip: text;
  background-clip: text;
}

.container:hover, .containerPreview {
  border: 2px solid transparent;
  border-image-slice: 1;
  background-image: linear-gradient(#21272A, #21272A), radial-gradient(circle at top left, #8A3FFC, #D02670);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: color .5s;
}

.uploadLogo {
  width: 30px;
  margin-bottom: 12px;
}
