
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: auto;
  max-width: 540px;
  width: 95vw;
}

.button {
  padding: 15px 10px;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.9rem;
  color: white;
  border: 2px solid #697077;
}

@media (max-width: 508px) {
  .buttonText {
    font-size: smaller;
  }
}


.button:hover,
.selected {
  color: white;
  border: 2px solid transparent;
  border-image-slice: 1;
  border-radius: 5px;
  background-image: linear-gradient(#21272A, #21272A), radial-gradient(circle at top left, #8A3FFC, #D02670);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: color .5s;
  font-size: 0.9rem;
}

.selected {
  background-image: linear-gradient(to right, #8A3FFC, #D02670) !important;
}

.selectedText {
  color: white;
}

.image {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 95vw;
  max-width: 540px;
}

.button:hover .buttonText {
  color: transparent;
  background-image: radial-gradient(circle at top left, #8A3FFC, #D02670);
  -webkit-background-clip: text;
  background-clip: text;
}