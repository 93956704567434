.waitContentContainer {
    margin: auto;
    margin-top: 50px;
}

.createButton {
  font-size: 1.5em !important;
  color: white;
  width: 350px;
  padding: 20px !important;
  background-image: linear-gradient(to right, #D6FC3F, #29D026) !important;
  cursor: pointer;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-width: fit-content;
}

.createButton:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
}

.waitContentContainer h3 {
  margin-top: 70px;
}

.line {
    border-top: 1px solid #2a2f31;
    width: 50vw;
    margin: auto;
    margin-top: 100px;
}