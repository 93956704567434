.paymentContainer {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymentButton {
  color: white;
  font-size: large;
  width: 320px;
  padding: 15px;
  background-image: linear-gradient(to right, #8A3FFC, #D02670);
  filter: brightness(70%);
  margin-bottom: 10px;
}

.paymentButton:hover {
  cursor: default;
}

.paymentReady {
  filter: none;
}

.paymentReady:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.emailInput {
  width: 285px;
  height: 50px;
  padding: 0 15px;
  font-size: 16px;
  border: 2px solid var(--color-subheader);
  border-radius: 8px;
  background-color: #21272A;
}

.isValid {
    background-image: linear-gradient(to right, #8A3FFC, #D02670);
    border: none;
    text-align: center;
}

.isValid:focus {
text-align: left;
}


.emailInput::placeholder {
  color: white;
  font-weight: bold;
}

.emailInput {
  color: white;
  font-weight: bold;
  outline: none;
}

.emailInput:focus, .emailInput:hover:not(.isValid) {
  border: 2px solid transparent;
  background-image: linear-gradient(var(--color-background), var(--color-background)), radial-gradient(circle at top left, #8A3FFC, #D02670);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-radius: 8px;
}

.emailInput:focus::placeholder, .emailInput:hover::placeholder {
    background-image: var(--gradient-text);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.paymentHeader {
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

.subheader {
  font-size: 18px;
}

.paymentFooter {
  margin-top: 4px;
  margin-bottom: 50px;
  font-size: 14px;
  text-align: center;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

