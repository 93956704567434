.input {
  background-image: linear-gradient(var(--color-background), var(--color-background)), var(--gradient-border);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  font-weight: bolder;
  font-size: 1.25em;
  transition: background-color 0.3s, color 0.3s;
  padding: 15px 10px;
  max-width: 518px;
  color: var(--color-white);
  margin: auto;
  width: 90vw;
  padding-left: 10px;
  border: 2px solid var(--color-subheader);
  position: relative;
  z-index: 2;
  margin-top: 10px;
  background-color: var(--color-background);
}

@media (max-width: 508px) {
  .input::placeholder {
      opacity: 1;
      font-size: smaller;
    }
       
    .error{
      padding-left: 20% !important;
      padding-right: 20% !important;
    }
        
}

.input::placeholder {
  color: var(--color-white);
  opacity: 1;
}

.input:hover::placeholder {
  color: transparent;
  background-image: var(--gradient-text);
  -webkit-background-clip: text;
  background-clip: text;
}

.input:hover {
  border: 2px solid transparent;
  border-image-slice: 1;
  background-image: linear-gradient(#21272A, #21272A), radial-gradient(circle at top left, #8A3FFC, #D02670);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: color .5s;
}

.input:focus  {
  color: var(--color-white);
  background-color: var(--gradient-button);
  outline: none;
  max-width: 518px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.animalText {
  font-weight: bold;
  margin-bottom: 10px;
}

.input:valid::placeholder {
  color: transparent;
}

.inputValid {
  color: var(--color-white);
  text-align: center;
  background-image: linear-gradient(to right, #8A3FFC, #D02670) !important;
  border-color: transparent;
}

.amount {
  text-decoration:line-through;
  color: #69707755
}

.error {
  color: red;
}

.examples {
  text-align: left;
  margin-left: 15px;
  color: var(--color-subheader);
  margin-top: 5px;
}