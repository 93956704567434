.faqItem {
  margin-bottom: 1em;
}

.faqTitle {
  background: none;
  border: none;
  color: var(--color-white);
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 1.25em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqContent {
  color: var(--color-subheader);
  margin-top: 0.5em;
  text-align: left;
  font-weight: 500;
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.icon {
  padding: 10px;
}