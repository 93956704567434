.logo {
  text-align: left;
  background: linear-gradient(to right, #8A3FFC, #D02670);
  -webkit-background-clip: text;
  color: transparent;
  margin-top: 0px;
  padding-left: 1em;
  width: fit-content;
}

.logo:hover {
  cursor: pointer;
}

