.container {
  border-top: 1px solid #2a2f31;
  margin-top: 100px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  text-align: center;
  color: var(--color-subheader);
  font-size: 0.8em;
}

.socialContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.email {
  border: 2px solid var(--color-subheader);
  font-size: 18px;
  border-radius: 8px;
  width: 240px;
  height: 50px;
  background-color: #21272A;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.email:hover {
    border: 2px solid transparent;
    background-image: linear-gradient(var(--color-background), var(--color-background)), radial-gradient(circle at top left, #8A3FFC, #D02670);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 8px;
}
@media (max-width: 508px) {
  .container {
    width: 90%;
  }
  .agreement {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
  .spec {
      padding-left: 15% !important;
      padding-right: 15% !important;
  }
}

@media (max-width: 390px) {
  .agreement {
      padding-left: 22% !important;
      padding-right: 22% !important;
  }
  .text {
      padding-left: 18% !important;
      padding-right: 18% !important;
  }
    .spec {
      padding-left: 12% !important;
      padding-right: 12% !important;
    }
}

