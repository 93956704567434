.grid {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 27vw;
}


@media screen and (max-width: 768px) {
  .grid {
    flex-direction: column;
    align-items: center;
  }
  .column {
    width: 90vw;
  }
}