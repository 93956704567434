@media (max-width: 508px) {
  .onUs {
    padding-left: 22% !important;
    padding-right: 22% !important;
  }
}

@media (max-width: 420px) {
  .onUs {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
}

@media (max-width: 380px) {
  .onUs {
    padding-left: 13% !important;
    padding-right: 13% !important;
  }
}