.header {
  color: var(--color-white);
  margin-bottom: 60px;
  font-size: 3em;
  margin-top: 60px;
}
.faqSection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 90vw;
  max-width: 600px;
  margin: auto;
}
