.error {
  color: red;
  margin: 0;
}

.errorContainer {
  margin-bottom: 100px;
}

.loading {
  max-width: 300px;
  max-height: 300px;
  width: 95vw;
  height: 95vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 25vh;
  margin-bottom: 25vh;
}

.loadBunny {
  width: 50px;
  height: 50px;
}


/* Mobile view */
@media (max-width: 508px) {
  .followTheseGuidelines  {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}